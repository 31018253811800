<template>
  <l-map
    :zoom="9"
    :center="[defaultLocation.lat, defaultLocation.lng]"
    ref="map"
  >
    <l-icon-default></l-icon-default>
    <l-tile-layer
      :url="tileProvider.url"
      :attribution="tileProvider.attribution"
    />
    <v-marker-cluster :options="options">
      <l-marker
        v-for="(location, i) in locations"
        :key="i"
        :lat-lng="[location.latitude, location.longitude]"
        :icon="icon"
      >
        <l-popup :content="tooltipContent(location)"></l-popup>
      </l-marker>
    </v-marker-cluster>
  </l-map>
</template>

<script>
import axios from 'axios'
import getDuration from '@/helpers/duration'
import currency from '@/helpers/currency'
import { LMap, LTileLayer, LIconDefault, LMarker, LPopup } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { icon } from 'leaflet'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
export default {
  components: {
    LMap,
    LTileLayer,
    LIconDefault,
    LMarker,
    // LTooltip,
    LPopup,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  props: {
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: -6.227373990352365,
        lng: 106.83368453847707,
      }),
    },
    locations: {
      type: Array,
      required: true,
    },
    tooltipType: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
    },
  },
  data() {
    return {
      icon: icon({
        iconUrl,
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      tileProvider: {
        attribution:
          '',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },
      loadingTooltip: false,
    }
  },
  methods: {
    async getNewAddress(lat, lng) {
      this.loadingTooltip = true
      let address = 'Unresolved address'
      try {
        const { data: { results } } = await axios.get('https://trueway-geocoding.p.rapidapi.com/ReverseGeocode', {
          headers: {
            'x-rapidapi-key': 'd5942df4d4mshb278ecbbd1999a8p1d84a6jsn146c68306430',
            'x-rapidapi-host': 'trueway-geocoding.p.rapidapi.com',
          },
          params: {
            location: `${lat},${lng}`,
            language: 'en',
          },
        })
        address = results[0].address
      } catch (error) {
        console.error('Reverse Geocode Error-> ', error)
      }
      this.loadingTooltip = false
      return address
    },
    tooltipContent(location) {
      if (this.loadingTooltip) return 'Loading...'
      else if (this.tooltipType === 'jarak_ke_sekolah') {
        return `
          <strong>Nama Murid: ${location.nama}</strong>
          <br/>
          <strong>Jarak ke Sekolah: ${location.jarak}KM</strong>
          <br/>
          <strong>Waktu Tempuh ke Sekolah: ${getDuration(location.waktu_tempuh_ke_sekolah)}</strong>
          <hr/>
          <strong>Latitude: ${location.latitude}</strong>
          <br/>
          <strong>Longitude: ${location.longitude}</strong>
        `
      } else if (this.tooltipType === 'penghasilan') {
        return `
          <strong>Nama Murid: ${location.nama}</strong>
          <br/>
          <strong>Penghasilan Orang Tua: Rp.${currency(location.total_penghasilan)}</strong>
          <hr/>
          <strong>Latitude: ${location.latitude}</strong>
          <br/>
          <strong>Longitude: ${location.longitude}</strong>
        `
      }
      return 'Unresolved Address'
    },
  },
}
</script>

<style>
</style>
